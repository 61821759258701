/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from 'react';
import propTypes from 'prop-types';

import { Flex, Tooltip, ToggleInput } from '@user-interviews/ui-design-system';
import { useTracking } from 'react-tracking';

import { AuthUserContext } from 'common/authorization';

import { trackingEvents } from 'lib/analytics';

export function KeyQuestion({
  isKey,
  pageTitle,
  position,
  onChange,
}) {
  const { account } = useContext(AuthUserContext);
  const { trackEvent } = useTracking();

  // Prevent a click from triggering parent event
  const handleClick = event => event.stopPropagation();
  const handleChange = ({ currentTarget: { checked } }) => {
    onChange({ isKey: checked });

    trackEvent({
      event: trackingEvents.SURVEY_KEY_QUESTION_SLIDER_CLICKED,
      is_enabled: checked,
      account_id: account?.id,
    });
  };

  const keyQuestionToggleInputId = `key-screener-question-toggle-${pageTitle}-${position}`;

  const tooltipText = (
    <div>
      Key screener questions will be highlighted in the applicants table and participant profiles.
    </div>
  );

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <Flex
      alignItems="center"
      container
      flexDirection="row"
      onClick={handleClick}
    >
      <ToggleInput
        id={keyQuestionToggleInputId}
        isChecked={isKey}
        labelLeft={false}
        labelText="Key screener question"
        onToggle={handleChange}
      />
      <Tooltip text={tooltipText} />
    </Flex>
  );
}

KeyQuestion.propTypes = {
  isKey: propTypes.bool.isRequired,
  pageTitle: propTypes.string.isRequired,
  position: propTypes.number.isRequired,
  onChange: propTypes.func.isRequired,
};
